.textField__multiline .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense {
   padding-top: 8px;
   padding-bottom: 5px;
}

.textField__oneLiner .MuiOutlinedInput-inputMarginDense {
   padding-top: 8px;
   padding-bottom: 8px;
   
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

