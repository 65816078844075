.background {
	background: url(./bg-1.jpg) no-repeat center center fixed;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
    height: calc(100vh - 64px);
    overflow: hidden;
}

.title__container {
	height: 100%;
	display: flex;
    align-items: center;
    flex-direction: column;
}

.title {
    font-family: "Montserrat", sans-serif;
	font-size: 10vmin;
	color: white;
    padding-top: 20vh;
}

.subtitle{
    font-family: "Montserrat", sans-serif;
	font-size: 2vmin;
	color: white;
}

